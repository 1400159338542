import { Dialog, Flexbox, HorizontalSeparator, Loader, Select } from 'components';
import { exportToJira } from '../../index.api';
import { ChangeEvent, useEffect, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Initiative, JiraIssueType, JiraMapping, JiraProject, JiraProjectIssueTypes, Preferences, PreferencesKeys, Story } from 'utils/types';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { getJiraProjectFields, getJiraProjects } from 'common/jira/index.api';
import { workspaceIntegrationSelector } from 'store/integrations';
import { getInitiativeById } from 'pages/Initiatives/initiatives.api';
import Fields from './components/fields';
import EmptyMapping from './components/emptyMapping';
import { ACTION_SET_GLOBAL_ERROR } from 'store/globalError';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { Dispatch, GetState } from 'store';
const classes = classNames.bind(styles);


export interface JiraExportPopupProps<T> {
    open: boolean;
    onClose: () => void;
    baseUrl: string;
    exportedData: T;
    onExport: (exportedData: T) => void;
}
type JiraFieldsReducerAction = {
    type: 'update';
    key: string;
    payload: any;
} | { type: 'reset' };

interface JiraFieldsReducerState {
    [key: string]: any;
}

export interface JiraFieldError {
    [key: string]: string;
}

export const jiraFieldsReducer = (state: JiraFieldsReducerState, action: JiraFieldsReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return {};
        default:
            return state;

    }
}

const InitiativeExportPopup = <T extends Story | Initiative>({ open, onClose, baseUrl, exportedData, onExport }: JiraExportPopupProps<T>) => {
    const dispatch = useDispatch();
    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);

    const [allProjects, setAllProjects] = useState<JiraProject[]>([]);
    const [selectedProject, setSelectedProject] = useState<JiraProject | undefined>();

    const [allIssueTypes, setAllIssueTypes] = useState<JiraIssueType[]>([]);
    const [selectedIssueType, setSelectedIssueType] = useState<JiraIssueType | null>(null);

    const [mappings, setMappings] = useState<JiraMapping[]>([]);

    const [jiraFields, setJiraFields] = useReducer(jiraFieldsReducer, {});

    const [exportError, setExportError] = useState<JiraFieldError>({});
    const [popupError, setPopupError] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [issueLoading, setIssueLoading] = useState(false)

    const [updatedInitiative, setUpdatedInitiative] = useState<Initiative | undefined>()
    const [preferences, setPreferences] = useState<Record<string, any>>({ main: {} })

    const loadPreferences = async () => {
        const preferences = await dispatch(getPreferences(PreferencesKeys.initiative))

        if (preferences && Array.isArray(preferences)) {
            setPreferences(preferences[0].value)
        }
    }

    useEffect(() => {
        loadPreferences()
    }, [])

    useEffect(() => {
        if (!open || workspaceIntegrations.length === 0) {
            return;
        }

        const jiraIntegration = workspaceIntegrations.find(integration => integration.name === 'JIRA');

        if (!jiraIntegration || !jiraIntegration.mappings || jiraIntegration.mappings.length === 0) {
            return
        }

        if (!selectedProject) {
            setPopupError('')
            setMappings(jiraIntegration.mappings);
            getProjects(jiraIntegration.mappings);

            async function getProjects(mappings: JiraMapping[]) {
                try {
                    setIsLoading(true)
                    const projects = (await dispatch(getJiraProjects())) as unknown as JiraProject[];
                    const projectWithMapping = projects.filter(project => {
                        return mappings.some(mapping => mapping.projectId === project.id);
                    });

                    setAllProjects(projectWithMapping);

                    const lastSelectedProjectId = preferences?.main?.lastSelectedProjectId;
                    const matchedProject = projectWithMapping.find(proj => proj.id === lastSelectedProjectId) || projectWithMapping[0];
                    setSelectedProject(matchedProject);

                    const projectIssueTypes = (await dispatch(getJiraProjectFields(matchedProject?.key))) as unknown as JiraProjectIssueTypes;
                    setAllIssueTypes(projectIssueTypes.issuetypes);

                    const currentMap = mappings.find(mapping => mapping.projectId === matchedProject?.id)
                    const currentIssueType = projectIssueTypes.issuetypes.find(issueType => issueType.id === currentMap?.initiativeMapping?.fieldId)

                    if (currentIssueType) {
                        setSelectedIssueType(currentIssueType)
                    }
                } catch (e: any) {
                    if (e.data.error) {
                        setPopupError(e.data.error)
                    }
                } finally {
                    setIsLoading(false)
                }
            }
        }
    }, [workspaceIntegrations, open, selectedProject, preferences])


    useEffect(() => {
        if (open && selectedProject) {
            setJiraFields({ type: 'reset' })

            if (updatedInitiative) {
                const currentMap = mappings.find(mapping => mapping.projectId === selectedProject?.id)

                const filterProdmapField = currentMap?.initiativeMapping?.fields.filter(f => f.prodmapField in updatedInitiative)

                function mapAndTransformData(data1: any, data2: any) {
                    const result: any = {};

                    data1?.forEach((mapping: any) => {
                        const prodmapField = mapping.prodmapField;
                        const jiraField = mapping.jiraField;
                        const values = mapping.values;

                        if (values) {
                            const prodmapValue = data2[prodmapField];
                            const mappedValue = values.find((val: any) => val.prodmapValue === prodmapValue);
                            result[jiraField] = mappedValue ? { id: mappedValue.jiraValue } : null;
                        } else if (prodmapField !== 'teams') {
                            result[jiraField] = data2[prodmapField];
                        }
                    });

                    return result;
                }

                const filteredObject = mapAndTransformData(filterProdmapField, updatedInitiative);

                Object.entries(filteredObject).forEach(([key, payload]) =>
                    setJiraFields({ type: 'update', key, payload })
                )

            }
        }
    }, [open, updatedInitiative, selectedProject])


    useEffect(() => {
        if (open) {
            loadInitiative()
        }
    }, [open])

    const loadInitiative = async () => {
        const initiative: Initiative = (await dispatch(getInitiativeById(exportedData.id))) as unknown as Initiative
        setUpdatedInitiative(initiative)
    }

    useEffect(() => {

        const getProjectIssueTypes = async () => {
            if (selectedProject?.key && !isLoading) {
                setIssueLoading(true);
                const projectIssueTypes = (await dispatch(getJiraProjectFields(selectedProject.key))) as unknown as JiraProjectIssueTypes;
                setAllIssueTypes(projectIssueTypes.issuetypes);

                const currentMap = mappings.find((mapping) => mapping.projectId === selectedProject?.id);
                const currentIssueType = projectIssueTypes.issuetypes.find((issueType) => issueType.id === currentMap?.initiativeMapping?.fieldId);
                if (currentIssueType) {
                    setSelectedIssueType(currentIssueType);
                }
                setIssueLoading(false);
            }
        };

        getProjectIssueTypes();
    }, [selectedProject])

    const onProjectChange = (e: ChangeEvent<{}>, value: JiraProject) => {
        setSelectedProject(value)

        dispatch(updatePreferences({ ...preferences, main: { ...preferences.main, lastSelectedProjectId: value.id } }, PreferencesKeys.initiative));
        clearExportError();
    }

    const onIssueTypeSelect = (e: ChangeEvent<{}>, value: JiraIssueType | null) => {
        setSelectedIssueType(value);
        clearExportError();
    }

    const onCreateIssue = async () => {
        if (updatedInitiative) {

            const params: { [key: string]: any } = {
                project: {
                    id: selectedProject?.id
                },
                issuetype: {
                    id: selectedIssueType?.id
                },
            }

            Object.entries(jiraFields).forEach(([key, value]) => {
                if (value?.id) {
                    value.id += '';
                } else if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].id) {
                            value[i].id += ''
                        }
                    }
                }

                if (selectedIssueType && (selectedIssueType.fields[key]?.schema.type === 'datetime' || selectedIssueType.fields[key]?.schema.type === 'date')) {
                    const d = new Date(value);
                    value = d.toISOString();

                }

                if (selectedIssueType && selectedIssueType.fields[key]?.schema.type === 'team'
                    && typeof value === 'object' && value.hasOwnProperty('id')
                ) {
                    value = value.id.replace('ari:cloud:teams::team/', '');
                }

                params[key] = value
            })
            try {
                setIsExportLoading(true)
                const newStory = (await dispatch(exportToJira(baseUrl, exportedData.id, params))) as unknown as T;
                onExport(newStory);
            } catch (e: any) {
                if (e.data.errorMessage) {
                    const error = JSON.parse(e.data.errorMessage);
                    setExportError(error)
                } else if (e.data.error) {
                    dispatch({ type: ACTION_SET_GLOBAL_ERROR, payload: e.data.error });
                }
            } finally {
                setIsExportLoading(false)
            }
        }

    }

    const clearExportError = () => {
        setExportError({});
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={'Export to Jira'}
            confirmButton={!isLoading && !!mappings.length && !popupError}
            confirmButtonLabel='Export'
            onConfirm={onCreateIssue}
            size='middle'
            confirmButtonLoading={isExportLoading}
        >
            <Flexbox className={classes('jiraExportDialogContainer')} vertical>
                {isLoading ? (<Flexbox fullWidth align justify><Loader disableShrink /></Flexbox>) :
                    selectedProject ?
                        <Flexbox fullWidth vertical>
                            <Flexbox className={classes('rowContainer')}>
                                <Select
                                    onChange={onProjectChange}
                                    value={selectedProject}
                                    options={allProjects}
                                    disableClearable
                                    label="Project"
                                    getOptionLabel={option => `${option.name} (${option.key})`}
                                />
                            </Flexbox>
                        </Flexbox>
                        :
                        popupError ? <Flexbox className={classes('popupError')}>Error message: {popupError}</Flexbox> :
                            <EmptyMapping />
                }
                {issueLoading ? (<Flexbox fullWidth align justify><Loader disableShrink /></Flexbox>) :
                    selectedIssueType && <Flexbox fullWidth vertical>
                        <Flexbox className={classes('rowContainer')}>
                            <Select
                                value={selectedIssueType}
                                options={allIssueTypes}
                                getOptionLabel={option => option.name}
                                onChange={onIssueTypeSelect}
                                errorText={exportError['issuetype']}
                                label='Issue type'
                                disableClearable
                                disabled
                            />
                        </Flexbox>
                        <HorizontalSeparator dashed />
                        <Fields
                            selectedIssueType={selectedIssueType}
                            setSelectedIssueType={setSelectedIssueType}
                            mappings={mappings}
                            selectedProject={selectedProject}
                            jiraFields={jiraFields}
                            setJiraFields={setJiraFields}
                            exportError={exportError}
                        />
                    </Flexbox>
                }
            </Flexbox>
        </Dialog>
    )
}

export default InitiativeExportPopup;